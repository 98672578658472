import React, { useState, useEffect } from 'react';
import ReactAudioPlayer from 'react-audio-player';

function AudioPlayer(props) {
  
  const [playCount, setPlayCount] = useState(props.playCount);

  const handleOnEnded = () => {
    if (playCount > 0) {
      setPlayCount(prevCount => prevCount - 1);
    }
  };

  useEffect(() => {
    if (playCount > 0) {
      // Access the audio player and start playback
      const audioPlayer = document.getElementById('my-audio-player');
      audioPlayer.play();
    }
  }, [playCount]);

  return (
    <div>
      <ReactAudioPlayer
        id="my-audio-player"
        src={props.src}
        onEnded={handleOnEnded}
      />
    </div>
  );
}

export default AudioPlayer;