const NewMilestone = (props) => {

  const postDetails = props?.postDetails;
  const totalCharLength = postDetails.milestoneTypeName.length;

  let maxWidth = '13vw'
  if(totalCharLength > 15 && totalCharLength <= 25) {
    maxWidth = '17vw';
  } else if(totalCharLength > 25) {
    maxWidth = '24vw';
  }

  if(postDetails) {

    return <h2 className="text-center post-type-box" style={{ maxWidth: maxWidth }}>
      {postDetails.milestoneTypeName}
    </h2>

  } else {
    return null;
  }

}

export default NewMilestone;
