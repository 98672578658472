import React, { useEffect, useCallback, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import {
  clearScreenAlertItem, setScreenViewAlert
} from '../../redux';

import AudioPlayer from './alert-partials/AudioPlayer';
import NewSale from './alert-partials/NewSale';
import NewMilestone from './alert-partials/NewMilestone';
import PlayerYoutube from './alert-partials/PlayerYoutube';
import PostDescription from './alert-partials/PostDescription';
import Viz from './alert-partials/Viz';

const ScreenAlert = (props) => {
  const marketing360LogoUrl = `/assets/img/m360logoBlack.png`;
  const salesscreen = useSelector((state) => state.screen);
  const screenAlertItem = salesscreen.screenAlertItem;
  const screenMuteAll = salesscreen.screenMuteAll;
  const screenAlertPlayingCounter = salesscreen.screenAlertPlayingCounter;
  const dispatch = useDispatch();
  const [ids, setIds] = useState([])
  const [items, setItems] = useState([])
  const [item, setItemForPlaying] = useState(null);
  const [showMedia, setShowMedia] = useState(false);

  const initFetch = useCallback(() => {

    if(screenAlertItem && screenAlertItem !== null) {

      setTimeout(() => {

        try {
          const id = `${screenAlertItem.postType}-${screenAlertItem.postDetails.id}`;

          if(ids.indexOf(id) === -1) {

            const _newIds = ids.concat(id);
            setIds(_newIds);

            const _newState = items.concat(screenAlertItem);
            dispatch(clearScreenAlertItem());
            setItems(_newState);

            if(item === null && showMedia === false) {
              setItemForPlaying(screenAlertItem);
              setShowMedia(true);
              dispatch(setScreenViewAlert(true));
            }

          }

        } catch(error) {
          console.log(error);
        }

      },200);
    }

  }, [ids, setShowMedia, showMedia, items, item, screenAlertItem, dispatch]);

  useEffect(() => {
    initFetch();
  }, [initFetch]);

  const playNextItem = () => {
    setShowMedia(false);
    setItemForPlaying(null);

    setTimeout(() => {
      /* if more than 1 alerts in queue */
      if(items.length > 1) {
        const nextItem = items[1];

        /** remove 0th index alert **/
        const newItemState = items;
        newItemState.shift();
        setItems(newItemState);
        setShowMedia(true);
        setItemForPlaying(nextItem);

      } else {
        /** reset state **/
        setItems([]);
        setItemForPlaying(null);
        dispatch(setScreenViewAlert(false));
      }

    }, 200);
  }

  let _content = '';

  if(showMedia && item !== null) {

    let _postDetails = ''; let _saleAmount = 0; let _AudioPlayer = '';
    if(item.postType === 'sale') {
      _saleAmount = item.postDetails?.amount;
      if(! screenMuteAll) {
        _AudioPlayer = <AudioPlayer
        src="https://salesscreen.s3.us-east-2.amazonaws.com/assets/tunes/ching.mp3" 
        playCount={parseInt(_saleAmount/1000, 10)} />
      }
      _postDetails = <NewSale screenAlertPlayingCounter={screenAlertPlayingCounter} postDetails={item.postDetails}  />      
    } else if(item.postType === 'milestone') {
      if(! screenMuteAll) {
        _AudioPlayer = <AudioPlayer
        src="https://salesscreen.s3.us-east-2.amazonaws.com/assets/tunes/mushroom.mp3" 
        playCount={1} />
      }
      _postDetails = <NewMilestone screenAlertPlayingCounter={screenAlertPlayingCounter} postDetails={item.postDetails} />
    }

    let logoSection = '';
    if(item.postDetails?.logoFileName) {
      logoSection = <Avatar className="teamLogoSection" variant="circle" src={item.postDetails.logoUrl} ></Avatar>
    }

    const userProfilPictureSection = <Avatar className="userProfilPicture"
      variant="circle" src={item.postDetails.userProfilPicture} >
      {item.postDetails.postedBy.charAt(0)}
    </Avatar>

    let screen_bg_class = `screen-sale-bg`;
    if(item.postType === 'milestone') {
      screen_bg_class = `screen-milestone-bg`;
    }

    _content =  <div className={salesscreen.screenViewAlert ? `announcement-container ${screen_bg_class}`: `announcement-container d-none`} >
            {_AudioPlayer}
            <table  width="100%" height="100%" cellPadding="10px">

              <tr>
                <td colSpan="2" height="10%">
                  <div style={{ marginLeft: '4%' }}>
                    <img
                      style={{ maxWidth: "10vw" }}
                      src={marketing360LogoUrl}
                      alt="Marketing 360®" title="Marketing 360®" />
                    <span style={{ marginLeft: '0.5vw', fontSize: '1.2vw', fontFamily: '"Source Sans 3", serif' }}>
                      #1 Marketing Platform® for Small Business
                    </span>
                  </div>
                </td>
              </tr>

              <tr>
                <td height="50%" width="50%"
                  align="center" valign="middle" >
                  <div className="userProfilPictureSection" >
                    {userProfilPictureSection}
                    <Viz saleAmount={_saleAmount} />
                    {logoSection}
                  </div>
                </td>
                <td align="left" valign="middle" >
                  <h4 className="posted-by">{item.postDetails.postedBy} | {item.postDetails.teamName}</h4>
                  { item.postDetails?.jobTitle && <h4 className="job-title">{item.postDetails.jobTitle}</h4> }
                  {_postDetails}
                </td>
              </tr>

              <tr>
                <td align="center" valign="top"  >
                  <PlayerYoutube ytLibrary={item.ytLibrary} playNextItem={playNextItem} mute={screenMuteAll} />
                  <Avatar className="screenAlertPlayingCounter">
                    {salesscreen.screenAlertPlayingCounter}
                  </Avatar>
                </td>
                <td  align="left" valign="top"  >
                  <PostDescription description={item.postDetails.description} />
                </td>
              </tr>
            </table>

      </div>
  }

  return (<>{_content}</>)
}

export default ScreenAlert;

/*
For testing
const screenAlertItem = {
  "postType": "milestone", // sale OR milestone
  "postDetails": {
      "milestoneTypeName": "New Site Live", //other
      "id": 10499,
      "accountNumber": "",
      "amount": 1000,
      "description": "Rebecca being persistent brought this one in the door. A full year of chasing them down! #APEX",
      "description": "We took a new site live with no revisions: https://www.marketing360.com",
      "deleted": false,
      "createdAt": "2024-02-15T23:10:16.000Z",
      "youtubeLibraryId": 100368,
      "industryId": 2,
      "teamId": 2,
      "userId": 1,
      "teamName": "Andromeda",
      "industryName": "HVAC Contractor",
      "industryPicture": "2982e8e1-618b-4a83-8353-57d0bc55ada3.png",
      "industryPictureUrl": "https://salesscreen-dev.s3.us-east-2.amazonaws.com/v2/industries-logos/2982e8e1-618b-4a83-8353-57d0bc55ada3.png",
      "postedBy": "Will Davis",
      "jobTitle": "Marketing Consultant",
      "userProfilPicture": `https://lh3.googleusercontent.com/a/AAcHTteY5o9C8OHX4jrTyOOjgPHYGqcPFmIEc_VvXicl9vkIjR4=s296-c`,
      "logoFileName": "686dfb20-238d-448b-873b-05bbd8b53ef5.png",
      "logoUrl": "https://salesscreen-dev.s3.us-east-2.amazonaws.com/v2/team-logos/ae890fa0-70df-4747-a224-a2b4d6adaecd.jpg",
      "logoResizedUrl": "https://salesscreen-dev.s3.us-east-2.amazonaws.com/v2/team-logos/resized-ae890fa0-70df-4747-a224-a2b4d6adaecd.jpg"
  },
  "ytLibrary": {
      "id": 100368,
      "title": "I Don't Want To Talk",
      "deleted": false,
      "nsfw": false,
      "createdAt": "2023-08-09T02:47:22.000Z",
      "youtubeId": "jKvvoaNqOuc",
      "youtubeIdStart": 5,
      "youtubeIdEnd": 35,
      "userId": 1
  }
}; //

 */
